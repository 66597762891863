import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    { path: '/', name: 'home', component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue') },
    { path: '/maps', name: 'maps', component: () => import(/* webpackChunkName: "maps" */ '../views/Maps.vue') },
    { path: '/mapfeu/:id', name: 'maps', component: () => import(/* webpackChunkName: "maps" */ '../views/Maps.vue') },
    { path: '/contact', name: 'contact', component: () => import(/* webpackChunkName: "contact" */ '../views/Contact.vue') },
    { path: '/visite', name: 'visite', component: () => import(/* webpackChunkName: "maps" */ '../views/Visite.vue') },
    { path: '/login', name: 'login', component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue') },
    { path: '/files', name: 'files', component: () => import(/* webpackChunkName: "files" */ '../views/Fichier.vue') },
    { path: '/regulateurs', name: 'regulateurs', component: () => import(/* webpackChunkName: "regulateurs" */ '../views/Regulateurs.vue') },
    { path: '/regulateur/:id', name: 'regulateur', component: () => import(/* webpackChunkName: "regulateur" */ '../views/Regulateur.vue') },
    { path: '/equipement/:id', name: 'feu', component: () => import(/* webpackChunkName: "equipement" */ '../views/Equipement.vue') },
    { path: '/actions', name: 'actions', component: () => import(/* webpackChunkName: "actions" */ '../views/Action.vue') },
    { path: '/feux', name: 'feux', component: () => import(/* webpackChunkName: "feux" */ '../views/Feux.vue') },
    { path: '/addaction/:id/:feu/:reg', name: 'addaction', component: () => import(/* webpackChunkName: "addaction" */ '../views/AddAction.vue') },
    { path: '/inters', name: 'inters', component: () => import(/* webpackChunkName: "inters" */ '../views/Inter.vue') },
    { path: '/periode', name: 'periode', component: () => import(/* webpackChunkName: "periode" */ '../views/Periode.vue') },
    { path: '/addinter/:id/:feu/:reg', name: 'addinter', component: () => import(/* webpackChunkName: "addinter" */ '../views/AddInter.vue') },
    { path: '*', redirect: '/' }
    ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
