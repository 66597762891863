<template>
	<nav class="navbar navbar-default" id="mytopbar">
		<div class="container-fluid">
			<div class="navbar-header">
				<button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar"
					aria-expanded="false" aria-controls="navbar">
					<span class="sr-only">Toggle navigation</span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
					<span class="icon-bar"></span>
				</button>
				<span class="navbar-brand">CGB</span>
			</div>
			<!-- Pas d'utilisateur connecté -->
			<div id="navbar" class="navbar-collapse collapse" v-if="menuNone">
				<ul class="nav navbar-nav">
					<li>
						<router-link :to="{ name: 'home' }"><i class="fa fa-home"></i> Accueil</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'contact' }"><i class="fa fa-address-book"></i> Contact</router-link>
					</li>
				</ul>
			</div>
			<!--/.nav-collapse -->

			<!-- Administrateur connecté -->
			<div id="navbar" class="navbar-collapse collapse" v-if="menuAdmin">
				<ul class="nav navbar-nav">
					<li>
						<router-link :to="{ name: 'home' }"><i class="fa fa-home"></i> Accueil</router-link>
					</li>
					<li v-if="menuHeli">
						<router-link :to="{ name: 'maps' }"><svg height="16" width="16" viewBox="0 0 16 16"
								xmlns="http://www.w3.org/2000/svg">
								<path :d="heli" />
							</svg> Hélistation</router-link>
					</li>
					<li v-else>
						<router-link :to="{ name: 'maps' }"><i class="fa fa-road"></i> Piste</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'regulateurs' }"><i class="fa fa-bolt"></i> Régulateurs/Départs
						</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'files' }"><i class="fa fa-file"></i> Fichiers</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'actions' }"><i class="fa fa-comment"></i> Actions</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'feux' }"><i class="fa fa-lightbulb-o"></i> Equipements</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'visite' }"><i class="fa fa-user"></i> Visite de piste</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'periode' }"><i class="fa fa-calendar"></i> Périodicité</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'contact' }"><i class="fa fa-address-book"></i> Contact</router-link>
					</li>
				</ul>
				<ul class="nav navbar-nav navbar-right">
					<img :src="pathLogo" v-if="isLogo" />
					<li><a href="#" @click="unconnect"><span class="menuitem">Déconnexion</span> <i
								class="fa fa-window-close"></i></a></li>
				</ul>
			</div>
			<!--/.nav-collapse -->

			<!-- Utilisateur connecté -->
			<div id="navbar" class="navbar-collapse collapse" v-if="menuUser">
				<ul class="nav navbar-nav">
					<li>
						<router-link :to="{ name: 'home' }"><i class="fa fa-home"></i> Accueil</router-link>
					</li>
					<li v-if="menuHeli">
						<router-link :to="{ name: 'maps' }"><svg height="16" width="16" viewBox="0 0 16 16"
								xmlns="http://www.w3.org/2000/svg">
								<path :d="heli" />
							</svg> Hélistation</router-link>
					</li>
					<li v-else>
						<router-link :to="{ name: 'maps' }"><i class="fa fa-road"></i> Piste</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'regulateurs' }"><i class="fa fa-bolt"></i> Régulateurs/Départs
						</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'files' }"><i class="fa fa-file"></i> Fichiers</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'actions' }"><i class="fa fa-comment"></i> Actions</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'feux' }"><i class="fa fa-lightbulb-o"></i> Equipements</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'visite' }"><i class="fa fa-user"></i> Visite de piste</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'periode' }"><i class="fa fa-calendar"></i> Périodicité</router-link>
					</li>
					<li>
						<router-link :to="{ name: 'contact' }"><i class="fa fa-address-book"></i> Contact</router-link>
					</li>
				</ul>
				<ul class="nav navbar-nav navbar-right">
					<img :src="pathLogo" v-if="isLogo" />
					<li><a href="#" @click="unconnect"><span class="menuitem">Déconnexion</span> <i
								class="fa fa-window-close"></i></a></li>
				</ul>
			</div>
			<!--/.nav-collapse -->

		</div>
		<!--/.container-fluid -->
	</nav>
</template>

<script>
import store from "../store";
import { logoutApi } from '../lib/api.js'
import { helicopter } from "../lib/svg.js";

export default {
	name: 'mytopbar',
	data() {
		return {
			typeUser: store.state.typeUser,
			logo: store.state.logo,
			heli: helicopter()
		}
	},
	computed: {
		menuAdmin: function () {
			return (store.state.typeUser == 2)
		},
		menuUser: function () {
			return (store.state.typeUser == 1)
		},
		menuNone: function () {
			return (store.state.typeUser <= 0)
		},
		menuHeli: function () {
			return (store.state.helistation == 0 ? false : true)
		},
		pathLogo: function () {
			return '/img/' + store.state.logo
		},
		isLogo: function () {
			return store.state.logo == '' ? false : true
		}
	},
	methods: {
		unconnect: function () {
			logoutApi(this).then(
				(response) => {
					if (response.data.error_number == 0) {
						store.commit('connexion', { token: '', idUser: 0, user: '', typeUser: 0, nomUser: '', piste: 0, nomPiste: '', helistation: 0 })
						if (this.$route === 'home') this.$forceUpdate()
						this.$router.push({ name: 'home' })
					} else {
						this.err = true
					}
				},
				(response) => {
					this.err = true
				})
		}
	}
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/design.scss';

svg {
	height: 16px;
	width: 16px;
	fill: currentcolor;
	display: inline-block;
	white-space: nowrap;
}

a,
a:hover,
a:link {
	color: white;
	vertical-align: middle;
	text-align: center;
}
</style>
